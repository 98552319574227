import axios from 'axios'
import { message } from 'ant-design-vue'
import store from '@/store'

const instance = axios.create({
  baseURL: store.state.baseURL,
  timeout: 15000,
  headers: { 'Content-Type': 'application/json' }
})

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.state.token}`
  return config
})

const getInstance = () => {
  return instance
}

/**
 * 统一http请求
 * @param method
 * @param url
 * @param params
 * @param opt {headers,callbacks}x
 * @param baseURL
 * @returns {Promise<unknown>}
 */
const request = (method = 'GET', url:string, params?: any, opt?: any, baseURL?: string) => {
  if (store.state.checkShouldRefresh) {
    const func = store.state.checkShouldRefresh as any
    const token = store.state.token
    func(token)
  }
  const instance = getInstance()
  method = method.toUpperCase()
  if (!opt) {
    opt = {}
  }
  const urlPrefix = opt.apiPrefix ?? store.state.config.apiPrefix
  const { headers = {}, callbacks = {} } = opt
  const {
    onHttpNotFound = () => { message.error('身份信息失效，请登录') },
    onHttpTimeOut = () => { message.error('请求错误或超时') },
    onHttpStandardError = (msg) => { message.error(msg) }
  } = callbacks
  return new Promise((resolve, reject) => {
    instance.request({
      // @ts-ignore
      method,
      url: urlPrefix + url,
      params: method === 'GET' ? params : null,
      data: ['POST', 'PATCH', 'PUT'].includes(method) ? params : null,
      headers,
      ...(baseURL && { baseURL })
    }).then(response => {
      const { data: { data } } = response
      if (data) {
        const { meta } = data
        if (meta) {
          data.pagination = {
            current: meta.current_page,
            total: meta.total,
            pageSize: parseInt(meta.per_page)
          }
        }
        resolve(data)
      } else {
        resolve(response.data)
      }
      resolve(response.data.data ?? response.data)
    }).catch(error => {
      if (!error.response) {
        onHttpTimeOut()
        reject(error.message)
        return
      }
      // error.response.data error.response.status error.message
      if (error.response.status === 401) {
        onHttpNotFound()
      }
      if ([400, 422].includes(error.response.status)) {
        onHttpStandardError(error.response.data.message)
      }
      if (error.response.status === 401 && (store.state as any).redirectLogin) {
        (store.state as any).redirectLogin()
      }
      reject(error.response.data)
    })
  })
}

const getRequest = (url: string, params?: any, opt?: any) => {
  return request('GET', url, params, opt)
}

const postRequest = (url: string, params?: any, opt?: any) => {
  return request('POST', url, params, opt)
}

const deleteRequest = (url: string, params?: any, opt?: any) => {
  return request('DELETE', url, params, opt)
}

export { request, getRequest, postRequest, deleteRequest }
